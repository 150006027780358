// Here you can add other styles
.imgBorder {
	border-radius: 3px;
	padding: 5px 5px;
	margin-top: 0px;
	width: unset;
	display: inline-block;
	height: unset;
}

.imgContainer {
	width: 140px;
	height: 140px;
	background: rgb(150, 150, 150);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imgContainer img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.uploadButton {
	position: absolute;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.erTableData {
	vertical-align: middle;
}

.erTableData td {
	vertical-align: middle;
}

.erBorder {
	border: 1px solid #e4e7ea;
	border-radius: 0.25rem;
	padding: 5px;
}

.sessionPopupContainer {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 500px;
	height: 500px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 99999;
	background-color: white;
	border: 1px solid lightgray;
	border-radius: 5px;
	button {
		margin: 0 10px;
	}
}

.app-footer {
	display: flex;
	justify-content: space-between;
}

// // The following hides spinners from numberic input fields
// input[type="number"]::-webkit-outer-spin-button,
// input[type="number"]::-webkit-inner-spin-button {
// 	-webkit-appearance: none;
// 	margin: 0;
// }
// input[type="number"] {
// 	-moz-appearance: textfield;
// }

.link-button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	//text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
}

.link-button:hover,
.link-button:focus {
	text-decoration: none;
}

@media (max-width: 992px) {
	.sm-p0 {
		padding: 0;
	}

	.sm-pt0 {
		padding-top: 0;
	}
}

// @media (min-width: 1900px) {
// 	.container {
// 		// 70%
// 		max-width: 1350px;
// 	}

// 	.card-body {
// 		padding: 1.75rem;
// 	}
// }

//retina displays
@media (min-width: 2000px) {
	.container {
		max-width: 1700px;
	}

	.card-body {
		padding: 2.5rem;
	}
}

.main {
	//background-image: url("/background.jpg");
	background-color: white;
}

.card {
	border: none;
}

.leaflet-container {
	height: 100%;
	width: 100%;
}

.project-map {
	height: 250px;
}

.public-project-map {
	//padding-bottom: 50px;
	//height: 100%;
	padding-bottom: 80%;
	width: 100%;
}

.picker-map {
	height: 600px;
}

.dashboard-map {
	height: 380px;
}
.form-control.is-invalid {
	background-image: none;
	padding-right: 5px;
}

.full-width {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}

.cover {
	//background-image: url("/cover_light_small.jpg");
	background-image: url("/banner4.jpg");
	background-size: cover;
	//background-position: left 200px bottom 0px;
}

@import url("https://fonts.googleapis.com/css?family=Amiko");
@import url("https://fonts.googleapis.com/css?family=Alegreya+Sans");

.circular--square {
	border-radius: 50%;
}

//   .btn-primary {
//     color: #fff;
//     background-color: #3271b1;
// 	border-color: #3271b1;

// }

// .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
//     color: #fff;
//     background-color: lighten(#3271b1, 7.5%);;
//     border-color: lighten(#3271b1, 10%); /*set the color you want here*/
// }

.btn-primary {
	color: #ead3e1;
	background-color: #952267;
	border-color: #952267;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
	//.btn-primary:active,
	color: #fff;
	background-color: lighten(#952267, 7.5%) !important;
	border-color: lighten(#952267, 10%) !important; /*set the color you want here*/
	box-shadow: none !important;
}

.btn-primary:disabled {
	color: #fff;
	background-color: lighten(#952267, 40%);
	border-color: lighten(#952267, 45%); /*set the color you want here*/
}

.leaflet-div-icon {
	background: transparent;
	border: none;
}

.shadow {
	-webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
	/* Similar syntax to box-shadow */
}

.dashboard-entity {
	//border-radius: 10%;
	border-radius: 50%;

	border-radius: 10%;

	display: block;
	margin: 0 auto;
}

.dashboard-entitycaption {
	text-align: center;
}

// dashboard map legend
.info {
	padding: 6px 8px;
	font: 14px/16px Arial, Helvetica, sans-serif;
	background: white;
	background: rgba(255, 255, 255, 0.8);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
}

.info h4 {
	margin: 0 0 5px;
	color: #777;
}

.legend {
	text-align: left;
	line-height: 18px;
	color: #555;
}

.legend i {
	width: 18px;
	height: 18px;
	float: left;
	margin-right: 8px;
	opacity: 0.7;
}

.link-button-blue {
	background-color: transparent;
	border: none;
	cursor: pointer;
	text-decoration: underline;
	display: inline;
	margin: 0;
	padding: 0;
	color: #0078a8;
}

.link-button-blue:hover,
.link-button-blue:focus {
	text-decoration: none;
}

.no-focus:hover,
.no-focus:active,
.no-focus:focus {
	text-decoration: none;
	outline: none;
}

.gdpr-popup > .modal-content {
	background-color: #f0f3f5;
}
.gdpr-popup > .modal-content > .modal-footer {
	border-top: none;
}
